<template>
     <div class="mod2 flex-col">

          <div>
               <div class="group22 flex_row_center_center">
                    <div class="outer5 flex_row_between_center">
                         <span class="paragraph1">
                              {{info.noticeTitle}}
                         </span>
                    </div>
               </div>

               <div class="leo1">
                    <div class="leo2">
                         <div class="group4" v-if="noticeType!=1">
                              <span class="word81">预算金额：</span>
                              <span class="info4">￥{{info.budget}}</span>
                         </div>

                         <div class="group4">
                              <span class="word81">开标时间：</span>
                              <span class="info45">{{info.openTime}}</span>
                         </div>

                         <div class="group4">
                              <span class="word81">投标截止时间：</span>
                              <span class="info45">{{info.deadline}}</span>
                         </div>

                         <div class="group4">
                              <span class="word81">招标单位：</span>
                              <span class="info45">{{info.uintName}}</span>
                         </div>


                    </div>
               </div>

               <div class="group7 flex_row_between_center">
                    <div class="flex-row" v-if="noticeType!=1">
                         <img class="label3" src="@/assets/inquiry/inq_icon3.png" />
                         <span class="word13">{{info.projectAddressAll}}</span>
                    </div>

                    <div class="box223 flex_row_center_center" @click.stop="seeDetail">查看详情
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     import { reactive, getCurrentInstance, ref } from 'vue'
     import { useRoute, useRouter } from 'vue-router'
     export default {
          props: {
               info: {
                    type: Object,
                    default: () => { }
               }
          },
          setup(props, { emit }) {

               const { proxy } = getCurrentInstance()
               const router = useRouter()
               const route = useRoute()
               const noticeType = route.query.noticeType
               const iniQuo = () => {

                    if (props.info.quoteState == 1) {
                         conditionCheck()
                    } else {
                         emit('openDialog', props.info.quoteId, props.info.quoteState)
                    }

               }

               const seeDetail = () => {
                    console.log(props.info, 'sssss')
                    if (noticeType == 1) {
                         router.push({
                              path: '/bid/winBidNoti',
                              query: {
                                   bidId: props.info.bidId,
                                   winId: props.info.winId
                              }
                         })
                    } else {
                         router.push({
                              path: '/bid/detail',
                              query: {
                                   bidId: props.info.bidId,
                              }
                         })
                    }



               }



               return {
                    iniQuo,
                    seeDetail,
                    noticeType
               }
          }
     }
</script>

<style lang="scss">
     .mosaic {

          .word11,
          .word12 {
               color: transparent;
               text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          }
     }


     .mod2 {
          width: 370px;
          height: 272px;
          box-shadow: 0px 0px 10px 0px rgb(120 122 129 / 20%);
          margin-bottom: 30px;
          margin-right: 22px;

          &:nth-child(3n) {
               margin-right: 0
          }
     }

     .group22 {

          height: 60px;
          width: 370px;
     }

     .outer5 {
          padding: 0 15px;
          width: 100%;
     }

     .leo1 {
          padding: 0 18px;

          .leo2 {
               padding-bottom: 15px;
               border-bottom: 1px solid #F7F8FB;
          }
     }

     .leo3 {
          height: 20px;

          .leo3_1 {
               background-color: rgba(239, 237, 237, 1);
               border-radius: 2px;
               width: 26px;
               height: 26px;
               color: rgba(255, 0, 0, 1);
               font-size: 14px;
          }

          .leo3_2 {
               color: rgba(255, 0, 0, 1);
               margin: 0 3px;
          }
     }

     .paragraph1 {
          color: rgba(51, 51, 51, 1);
          font-size: 16px;
          word-break: break-all;
          line-height: 20px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
     }

     .outer6 {}

     .mod3 {
          display: flex;
          align-items: center;
          margin-left: 10px;
     }

     .icon2 {
          width: 18px;
          height: 18px;
     }

     .word6 {
          color: rgba(51, 51, 51, 1);
          font-size: 12px;
          white-space: nowrap;
          display: block;
          margin-left: 3px;
          margin-top: 3px;
     }

     .word7 {
          width: 61px;
          height: 11px;

          color: rgba(255, 255, 255, 1);
          font-size: 12px;
          font-family: SourceHanSansCN-Regular;
          text-align: left;
          white-space: nowrap;
          line-height: 12px;
          display: block;
          margin: 12px 0 0 8px;
     }


     .group3 {
          width: 339px;
          margin: 14px 0 0 15px;
          border-bottom: 1px solid #F7F8FB;
          padding-bottom: 10px;
     }

     .pic1 {
          width: 54px;
          height: 54px;
     }

     .paragraph2 {
          color: rgba(51, 51, 51, 1);
          font-size: 13px;
          line-height: 24px;
          margin: 0px 0 0 12px;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
     }

     .txt5 {

          color: rgba(149, 149, 149, 1);
          font-size: 12px;
          font-family: SourceHanSansCN-Regular;
          text-align: left;
          white-space: nowrap;
          line-height: 12px;
          display: block;
          margin-left: 12px;
     }

     .img2 {
          width: 340px;
          height: 1px;
          margin: 15px 0 0 15px;
     }

     .group4 {

          font-size: 0;
          font-family: SourceHanSansCN-Regular;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 13px;
          margin: 13px 0 0 0px;
     }

     .word81 {

          color: #95989C;
          font-size: 13px;
          font-family: SourceHanSansCN-Regular;
          text-align: left;
          white-space: nowrap;
          line-height: 13px;
     }

     .info4 {
          color: rgba(255, 0, 0, 1);
          font-size: 13px;
          font-family: SourceHanSansCN-Regular;
          text-align: left;
          white-space: nowrap;
     }

     .info45 {
          color: #333333;
          font-size: 13px;
          font-family: SourceHanSansCN-Regular;
          text-align: left;
          white-space: nowrap;
     }

     .group5 {


          font-family: SourceHanSansCN-Regular;
          text-align: left;
          white-space: nowrap;
          line-height: 14px;
          margin: 10px 0 0 0px;
     }

     .word9 {
          width: 180px;
          height: 14px;

          color: rgba(51, 51, 51, 1);
          font-size: 13px;
          font-family: SourceHanSansCN-Regular;
          text-align: left;
          white-space: nowrap;
          line-height: 14px;
     }

     .word10 {
          width: 180px;
          height: 14px;

          color: rgba(102, 102, 102, 1);
          font-size: 13px;
          font-family: SourceHanSansCN-Regular;
          text-align: left;
          white-space: nowrap;
          line-height: 14px;
     }

     .group6 {
          margin: 10px 0 0 0px;
     }

     .mod5 {

          font-size: 0;
          font-family: SourceHanSansCN-Regular;
          text-align: left;
          white-space: nowrap;
          line-height: 13px;
          margin-top: 3px;
     }

     .info5 {

          color: rgba(51, 51, 51, 1);
          font-size: 13px;
          font-family: SourceHanSansCN-Regular;
          text-align: left;
          white-space: nowrap;
          line-height: 13px;
     }

     .word11 {
          width: 84px;
          height: 12px;

          color: rgba(102, 102, 102, 1);
          font-size: 13px;
          font-family: SourceHanSansCN-Regular;
          text-align: left;
          white-space: nowrap;
          line-height: 13px;
     }

     .label2 {
          width: 1px;
          height: 18px;
          margin-left: 88px;
     }

     .mod6 {
          width: 124px;
          height: 12px;

          font-size: 0;
          font-family: SourceHanSansCN-Regular;
          text-align: left;
          white-space: nowrap;
          line-height: 13px;
          margin: 3px 0 0 33px;
     }

     .txt6 {
          width: 124px;
          height: 12px;

          color: rgba(51, 51, 51, 1);
          font-size: 13px;
          font-family: SourceHanSansCN-Regular;
          text-align: left;
          white-space: nowrap;
          line-height: 13px;
     }

     .word12 {
          width: 124px;
          height: 12px;

          color: rgba(102, 102, 102, 1);
          font-size: 13px;
          font-family: SourceHanSansCN-Regular;
          text-align: left;
          white-space: nowrap;
          line-height: 13px;
     }

     .img3 {
          width: 340px;
          height: 1px;
          margin: 17px 0 0 15px;
     }

     .group7 {
          margin: 12px 0 0 14px;
     }

     .label3 {
          width: 14px;
          height: 19px;
     }

     .word13 {

          color: rgba(51, 51, 51, 1);
          font-size: 12px;
          font-family: SourceHanSansCN-Regular;
          text-align: left;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          word-break: break-all;
          line-height: 12px;
          margin: 4px 0 0 10px;
     }

     .box223 {
          width: 95px;
          height: 36px;
          border: 1px solid #285FDE;
          border-radius: 18px;
          text-align: center;
          line-height: 36px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #285FDE;
          margin-right: 20px;
          cursor: pointer;
     }

     .txt7 {

          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          font-family: SourceHanSansCN-Regular;
          white-space: nowrap;
          display: block;
          cursor: pointer;
     }

     .mod1 {
          background-color: rgba(255, 255, 255, 1);
          border-radius: 6px;
          min-height: 345px;
          width: 532px;
          justify-content: space-between;

          .bd1 {
               height: 34px;
               margin: 20px 0 0 243px;
               justify-content: space-between;

               .word1 {
                    width: 45px;
                    height: 23px;

                    color: rgba(0, 0, 0, 1);
                    font-size: 22px;
                    font-family: MicrosoftYaHei-Bold;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 23px;
                    margin-top: 11px;
                    display: block;
               }

               .label1 {
                    width: 16px;
                    height: 16px;
                    margin-right: 16px;
                    cursor: pointer;
               }
          }

          .bd2 {
               width: 404px;
               height: 16px;
               margin: 31px 0 0 59px;

               .word2 {
                    width: 404px;
                    height: 16px;

                    color: rgba(18, 18, 18, 1);
                    font-size: 16px;
                    font-family: MicrosoftYaHei;
                    text-align: center;
                    white-space: nowrap;
                    line-height: 16px;
                    display: block;
               }
          }

          .bd4 {
               margin: 45px 0 0 0px;

          }


          .bd3 {
               width: 345px;
               height: 18px;
               margin-bottom: 20px;

               .layer1 {
                    background-color: rgba(102, 102, 102, 1);
                    border-radius: 50%;
                    width: 6px;
                    height: 6px;
                    margin-top: 6px;
               }

               .info1 {
                    width: 57px;
                    height: 13px;

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    font-family: MicrosoftYaHei;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    display: block;
                    margin: 3px 0 0 11px;
               }

               .label2 {
                    width: 18px;
                    height: 18px;
               }

               .word3 {
                    width: 43px;
                    height: 12px;

                    color: rgba(102, 102, 102, 1);
                    font-size: 14px;
                    font-family: MicrosoftYaHei;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    display: block;
                    margin: 0px 0 0 6px;
               }
          }


          .bd6 {
               width: 127px;
               height: 36px;
               margin: 48px 0 28px 203px;
               cursor: pointer;

               .mod2 {
                    background-color: rgba(8, 113, 243, 1);
                    border-radius: 4px;
                    height: 36px;
                    width: 127px;

                    .txt2 {
                         width: 66px;
                         height: 16px;

                         color: rgba(255, 255, 255, 1);
                         font-size: 16px;
                         font-family: MicrosoftYaHei;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 16px;
                         display: block;
                         margin: 10px 0 0 20px;
                    }
               }
          }
     }
</style>