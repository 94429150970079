<template>
     <div class="bid_index">

          <div class="bid_z">

               <div class="z_con">



                    <div class="breadcrumb flex_row_start_center">
                         <router-link to="/bid/index">
                              <div class="crumb">招标信息</div>
                         </router-link>
                         <span class="el-icon-arrow-right"></span>
                         <div class="crumb last">{{type}}{{noticeType}}</div>

                    </div>


                    <div class="bid_main">
                         <div class="outer3 flex_row_start_center bid_query">
                              <span class="txt1">招标主题:</span>
                              <div class="bd1 flex-col">
                                   <input type="text" v-model="title">
                              </div>

                              <span class="word3">项目实施地区：</span>
                              <div class="bd5 flex-col">
                                   <el-cascader ref="cascaderAddr" v-model="deplArea" :options="areaData"
                                        placeholder="请选择所在地区" :props="cascaderProps2" size="small" clearable
                                        @change="inputEvent">
                                   </el-cascader>
                              </div>
                              <div class="bd6 flex-col" @click="confirm"><span class="info3">搜索</span></div>
                              <div class="bd7 flex-col" @click="reset"><span class="word5">重置</span></div>
                         </div>
                         <div v-if="bidList.list&&bidList.list.length">
                              <div class="inq_list flex_row_start_start">
                                   <bidItem v-for="(item,index) in bidList.list" :key="index" :info="item"></bidItem>
                              </div>

                              <!-- 分页 -->
                              <div class="flex_row_center_center sld_pagination">
                                   <el-pagination @current-change="handleCurrentChange"
                                        v-model:currentPage="bidList.page.current" :page-size="bidList.page.pageSize"
                                        layout="prev, pager, next, jumper" :total="bidList.page.total"
                                        :hide-on-single-page="true">
                                   </el-pagination>
                              </div>
                         </div>
                         <div class="bid_empty flex_column_center_center" v-else>
                              <img src="@/assets/inquiry/inq_empty.png" alt="">
                              <p>暂无数据</p>
                         </div>

                    </div>


               </div>
          </div>

     </div>
</template>

<script>
     import { ref, reactive, getCurrentInstance, toRefs, onMounted } from 'vue'
     import bidItem from './component/bidItem.vue'
     import { useRoute } from 'vue-router'
     import areaData from "@/assets/bid_area.json";
     import { inqUtils } from '@/utils/inquiry_utils.js'

     export default {
          components: {
               bidItem,
          },
          setup(props) {
               const { areaDataArray, cascaderProps2, disabledDate } = inqUtils();
               const { proxy } = getCurrentInstance()
               const route = useRoute()

               const page = reactive({
                    pageSize: 6,
                    current: 1,
                    total: 10
               })

               const param = reactive({
                    current: 1,
                    pageSize: 6,
               })

               const searchDTO = reactive({
                    title: '',
                    code: '',
                    projectAddressAll: '',
                    projectProvinceCode: '',
                    deplArea: ''
               })




               const typeMap = reactive({
                    1: '货物招标',
                    2: '工程招标',
                    3: '服务招标'
               })

               const noticeTypeMap = reactive({
                    0: '招标公告',
                    1: '中标公告',
                    3: '终止公告'
               })

               const noticeType = noticeTypeMap[route.query.noticeType]
               const type = typeMap[route.query.type]

               const bidList = reactive({
                    list: [],
                    page: {}
               })

               const inputEvent = () => {
                    const checkedNode = proxy.$refs["cascaderAddr"].getCheckedNodes();
                    if (checkedNode[0]) {
                         searchDTO.projectAddressAll = checkedNode[0].pathLabels.join('/')
                         searchDTO.projectProvinceCode = searchDTO.deplArea[0]
                    }

               }

               const handleCurrentChange = (e) => {
                    param.current = e
                    getBidList()
               }


               const getBidList = () => {
                    proxy.$get('v3/business/front/enterBid/list', { ...param, type: route.query.type, noticeType: route.query.noticeType }).then(res => {
                         if (res.state == 200) {
                              bidList.list = res.data.list
                              bidList.page = res.data.pagination
                         }
                    })
               }

               const confirm = () => {
                    Object.keys(searchDTO).forEach(item => {
                         if (searchDTO[item]) {
                              param[item] = searchDTO[item]
                         } else {
                              delete param[item]
                         }
                    })
                    param.current = 1
                    delete param.deplArea
                    getBidList()
               }

               const reset = () => {
                    Object.keys(searchDTO).forEach(item => {
                         delete param[item]
                         searchDTO[item] = ''
                    })
                    getBidList()
               }

               onMounted(() => {
                    getBidList()
               })


               return {
                    page,
                    ...toRefs(searchDTO),
                    bidList,
                    confirm,
                    reset,
                    handleCurrentChange,
                    areaData,
                    cascaderProps2,
                    bidList,
                    inputEvent,
                    noticeType,
                    type,
                    typeMap,
                    noticeType

               }
          }
     }
</script>

<style lang="scss">
     input::placeholder {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #c0c4cc;
     }

     ::-webkit-input-placeholder {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #c0c4cc;
     }

     /* 使用webkit内核的浏览器 */
     :-moz-placeholder {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #c0c4cc;
     }

     /* Firefox版本19+ */
     :-ms-input-placeholder {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #c0c4cc;
     }

     /* IE浏览器 */

     /*添加css样式*/
     input::-webkit-outer-spin-button,
     input::-webkit-inner-spin-button {
          -webkit-appearance: none;
     }

     input[type="number"] {
          -moz-appearance: textfield;
     }


     .breadcrumb {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          margin-bottom: 20px;

          .crumb {
               color: #777777;
               cursor: pointer;

          }

          .last {
               color: #333333;
          }
     }


     .bid_header {
          position: relative;
          height: 300px;
          width: 100%;

          .header_bg {
               background-position: center center;
               width: 100%;
               height: 301px;
               position: absolute;
               left: 0;
               top: 0;
               /* margin-left: -960px; */
               background-repeat: no-repeat;
               background-size: cover;
          }
     }

     .z_con {
          width: 1200px;
          margin: 0 auto;

     }

     .bid_z {
          background: #F5F5F5;
          padding-top: 20px;
          width: 100%;
          padding-bottom: 30px;

          .bid_main {

               background: #FFFFFF;
               padding: 20px;

               .outer3 {
                    .txt1 {
                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;
                         white-space: nowrap;
                    }

                    .bd1 {
                         border-radius: 3px;
                         width: 220px;
                         height: 30px;
                         border: 1px solid #C9C9C9;
                         margin-left: 10px;
                         padding: 0 10px;

                         input {
                              height: 30px;
                              border: none;
                              outline: none;
                              background: transparent;
                         }
                    }

                    .txt2 {
                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         margin-left: 15px;
                         white-space: nowrap;
                    }

                    .bd2 {
                         background-color: rgba(247, 248, 250, 1);
                         border-radius: 3px;
                         height: 36px;
                         border: 1px solid rgba(201, 201, 201, 1);
                         margin-left: 12px;
                         width: 76px;
                    }

                    .bd3 {
                         background-color: rgba(247, 248, 250, 1);
                         border-radius: 3px;
                         height: 36px;
                         border: 1px solid rgba(201, 201, 201, 1);
                         margin-left: 6px;
                         width: 76px;
                    }

                    .bd2,
                    .bd3 {
                         padding: 0 10px;

                         input {
                              height: 30px;
                              border: none;
                              outline: none;
                              background: transparent;
                         }

                    }


                    .info1 {
                         width: 37px;
                         height: 11px;
                         color: rgba(153, 153, 153, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 12px;
                         display: block;
                         margin: 12px 0 0 9px;
                    }

                    .word2 {
                         color: rgba(51, 51, 51, 1);
                         font-size: 20px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         display: block;
                         margin-left: 6px;
                    }


                    .txt3 {
                         width: 37px;
                         height: 12px;
                         color: rgba(153, 153, 153, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 12px;
                         display: block;
                         margin: 11px 0 0 11px;
                    }

                    .txt4 {
                         white-space: nowrap;
                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         margin-left: 12px;
                    }

                    .bd4 {
                         background-color: rgba(247, 248, 250, 1);
                         border-radius: 3px;
                         height: 36px;
                         border: 1px solid rgba(201, 201, 201, 1);
                         margin-left: 14px;
                         width: 228px;
                    }

                    .bd4,
                    .bd5 {

                         .el-select,
                         .el-input__inner {
                              height: 30px;
                              line-height: 30px;
                              border: none;
                              background: transparent;

                              &:hover {
                                   border: none
                              }
                         }

                         .el-input__icon {
                              line-height: 30px;
                         }
                    }

                    .layer1 {
                         width: 207px;
                         height: 11px;
                         margin: 12px 0 0 10px;
                    }

                    .info2 {
                         width: 37px;
                         height: 11px;
                         color: rgba(153, 153, 153, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 12px;
                         display: block;
                    }

                    .icon1 {
                         width: 13px;
                         height: 7px;
                         margin-top: 3px;
                    }

                    .word3 {

                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         white-space: nowrap;
                         display: block;
                         margin-left: 31px;
                    }

                    .bd5 {
                         border-radius: 3px;
                         height: 30px;
                         border: 1px solid #C9C9C9;
                         margin-left: 11px;
                         width: 220px;
                    }

                    .group1 {
                         width: 83px;
                         height: 11px;
                         margin: 12px 0 0 11px;
                    }

                    .word4 {
                         width: 37px;
                         height: 11px;
                         color: rgba(153, 153, 153, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 12px;
                         display: block;
                    }

                    .label1 {
                         width: 13px;
                         height: 7px;
                         margin-top: 3px;
                    }

                    .bd6 {
                         background-color: rgba(40, 95, 222, 1);
                         border-radius: 4px;
                         height: 32px;
                         width: 68px;
                         margin: 1px 0 0 23px;
                    }

                    .info3 {
                         width: 28px;
                         height: 13px;
                         color: rgba(255, 255, 255, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                         display: block;
                         margin: 9px 0 0 19px;
                         cursor: pointer;
                    }

                    .bd7 {
                         border-radius: 4px;
                         height: 32px;
                         border: 1px solid rgba(40, 95, 222, 1);
                         width: 68px;
                         margin: 1px 0 0 10px;
                    }

                    .word5 {
                         width: 29px;
                         height: 13px;
                         color: rgba(40, 95, 222, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                         display: block;
                         margin: 9px 0 0 19px;
                         cursor: pointer;
                    }
               }

               .inq_list {
                    flex-wrap: wrap;
                    margin-top: 41px;
               }

          }
     }

     .bid_empty {

          padding-top: 140px;
          padding-bottom: 200px;

          img {
               width: 120px;
               height: 119px;
          }

          p {
               font-size: 14px;
               font-family: Source Han Sans CN;
               font-weight: 400;
               color: #333333;
               margin-top: 34px;
          }
     }



     .bid_index {}
</style>